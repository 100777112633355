import { useRef, useEffect } from 'react';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';

import { v4 as uuidv4 } from 'uuid';

import { styled } from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import { StepIconProps } from '@mui/material/StepIcon';
import { Button, StepLabel } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import Modal from 'src/presentations/components/molecules/modal';
import { FormContainer } from 'src/presentations/components/organisms/forms/FormContainer';
import { useInvoiceFileProcessing, useUploadInvoice } from 'src/use-cases/invoice/useInvoices';
import { InvoiceUploadSignedUrlDto, InvoiceUploadSignedUrlRequestDto } from 'src/generated/services/TFinancialApi';
import IconExport from 'src/assets/images/icons/upload-icon.svg';
import IconUpload from 'src/assets/images/icons/document-uploaded.svg';
import { UploadStep } from './types';
import { TopInfo } from './TopInfo';
import { MainContent } from './MainContent';
import { useUploadSelector } from './useUploadSelector';

const steps = [
  { id: UploadStep.Step1_SelectInvoice, label: 'Select Invoices' },
  { id: UploadStep.Step2_UploadStatus, label: 'Processing Status' },
];

const StyledModal = styled(Modal)({
  '& .MuiDialog-paper': {
    maxWidth: '1000px',
    height: 'calc(100% - 64px)',
    borderRadius: '16px',

    '& .MuiDialogContent-root': {
      padding: '0',
      backgroundColor: '#F5F6FA',
    },
  },
});

const StyledStepper = styled(Stepper)({
  '& .MuiStepConnector-root': {
    padding: '0 8px',
  },
  '& .MuiStepConnector-line': {
    borderTopStyle: 'dashed',
    borderTopWidth: '2px',
  },
  '& .Mui-active .MuiStepConnector-line, & .Mui-completed .MuiStepConnector-line': {
    borderTopStyle: 'solid',
    borderColor: '#00992B',
  },
  '& .MuiStepLabel-label.Mui-active, & .MuiStepLabel-label.Mui-completed': {
    color: '#00992B',
    fontWeight: 'bold',
  },
  '& .MuiStepIcon-root': {
    color: '#ffffff',
    width: '32px',
    height: '32px',
    border: '1px solid #DFDFDF',
    borderRadius: '100%',

    '& .MuiStepIcon-text': {
      fill: '#00992B',
      fontWeight: 'bold',
    },

    '&.Mui-active': {
      color: '#00992B',
      borderColor: '#00992B',

      '& .MuiStepIcon-text': {
        fill: '#ffffff',
      },
    },
  },
});

export const UploadInvoice = () => {
  const {
    formContext,
    isShowUpload,
    isMinimized,
    activeStep,
    modality,
    batchId,
    isAllProcessCompleted,
    serviceProvider,
    uploadResults,
    isLoadingUpload,
    setIsMinimized,
    setActiveStep,
    setUploadResults,
    setBatchId,
    setUploadFinishItems,
    setIsLoadingUpload,
    onCloseUpload,
    onResetUpload,
    setUploadProcessings,
    setIsAllProcessCompleted,
  } = useUploadSelector();

  const isMinimizedRef = useRef<boolean>();
  const autoCloseTimerRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const location = useLocation();

  isMinimizedRef.current = isMinimized;

  const { mutateAsync: runUploadInvoice } = useUploadInvoice();

  const isApiEnabled = batchId && batchId !== '' && !isAllProcessCompleted;
  const { data } = useInvoiceFileProcessing(batchId, isApiEnabled);

  useEffect(() => {
    if (data?.length > 0) {
      const completedItems = data?.filter((item) => ['COMPLETE', 'TRANSFORM_ERROR', 'OCR_ERROR', 'MESSAGE_PROCESSING_FAILED'].includes(item?.status));
      if (data?.length === completedItems?.length) {
        setIsAllProcessCompleted(true);
      }
      setUploadProcessings(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (isShowUpload) {
      setIsMinimized(true);
      document.body.style.overflow = 'visible';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (isShowUpload && isMinimized && isAllProcessCompleted) {
      autoCloseTimerRef.current = setTimeout(() => {
        document.body.style.overflow = 'visible';
        setIsAllProcessCompleted(false);
        onCloseUpload();
        setTimeout(() => {
          onResetUpload();
        }, 500);
      }, 7000);
    }
    return () => {
      if (autoCloseTimerRef.current) {
        clearTimeout(autoCloseTimerRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowUpload, isMinimized, isAllProcessCompleted]);

  const formValues = formContext.watch();

  const onUploadInvoices = async () => {
    setIsLoadingUpload(true);

    const files = formValues?.invoiceFiles;

    if (files?.length > 0) {
      const fileNames = [];
      files.forEach((file: File) => {
        fileNames.push(file?.name);
      });

      const uploadData = {
        serviceProviderCode: serviceProvider ?? null,
        modality: modality ?? null,
        fileNames,
        batchId: uuidv4(),
      } as InvoiceUploadSignedUrlRequestDto;

      try {
        const uploadResp = await runUploadInvoice(uploadData);
        setIsLoadingUpload(false);
        setBatchId(uploadResp?.batchId || '');
        setUploadResults(uploadResp?.uploadUrls || []);
        setActiveStep(UploadStep.Step2_UploadStatus);
      } catch (error) {
        console.warn(error);
        setIsLoadingUpload(false);
      }
    }
  };

  const onFinishUpload = (data: InvoiceUploadSignedUrlDto) => {
    setUploadFinishItems((currentItems) => {
      const newItems = [...currentItems];
      if (!newItems.find((item) => item?.dataPipelineProcessingId === data?.dataPipelineProcessingId)) {
        newItems.push(data);
      }
      return newItems;
    });
  };

  const CustomStepIcon = (props: StepIconProps) => {
    const { active, completed, icon } = props;

    return (
      <span
        className={clsx('flex items-center justify-center w-36 h-36 text-20 font-bold rounded-full', {
          'bg-primary/25 text-primary': completed,
          'bg-primary text-white': active,
          'bg-white text-primary border border-neutral-20': !active && !completed,
        })}
      >
        {icon}
      </span>
    );
  };

  return (
    <>
      <StyledModal open={isShowUpload} onClose={onCloseUpload} fullWidth maxWidth='md' sx={{ visibility: isMinimized ? 'hidden' : 'visible' }}>
        <FormContainer formContext={formContext} FormProps={{ className: 'h-full flex flex-col' }}>
          <div className='px-24 pb-20 pt-24 border-b border-neutral-20 bg-white'>
            <div className='py-12 px-24 bg-neutral-10 rounded-8 mb-20'>
              <StyledStepper activeStep={activeStep}>
                {steps.map(({ id, label }) => (
                  <Step key={id}>
                    <StepLabel color='inherit' StepIconComponent={CustomStepIcon}>
                      {label}
                    </StepLabel>
                  </Step>
                ))}
              </StyledStepper>
            </div>

            <TopInfo />
          </div>

          <MainContent onFinishUpload={onFinishUpload} />

          <div className='mt-auto px-24 py-20 border-t border-neutral-20 flex items-center justify-end gap-12 bg-white'>
            {activeStep === UploadStep.Step1_SelectInvoice && (
              <>
                <Button type='button' variant='outlined' color='inherit' onClick={onCloseUpload} sx={{ textTransform: 'capitalize', borderColor: '#dfdfdf' }}>
                  Discard
                </Button>
                <Button
                  type='button'
                  variant='contained'
                  color='primary'
                  onClick={onUploadInvoices}
                  sx={{ textTransform: 'capitalize' }}
                  disabled={!formValues?.invoiceFiles || formValues?.invoiceFiles?.length === 0}
                >
                  {isLoadingUpload ? 'Loading...' : 'Start Upload'}
                </Button>
              </>
            )}
            {activeStep === UploadStep.Step2_UploadStatus && (
              <Button
                type='button'
                variant='outlined'
                color='inherit'
                disabled={!batchId || batchId === ''}
                onClick={() => {
                  if (batchId && batchId !== '') {
                    setIsMinimized(true);
                    document.body.style.overflow = 'visible';
                  }
                }}
                sx={{ textTransform: 'capitalize', borderColor: '#dfdfdf' }}
              >
                Close
              </Button>
            )}
          </div>
        </FormContainer>
      </StyledModal>

      {isShowUpload && isMinimized && !isAllProcessCompleted && (
        <div className='fixed bottom-16 left-16 z-20 px-16 py-10 rounded-6 bg-[#202020] text-white flex items-center'>
          <div className='pr-48 flex items-center gap-10'>
            <img src={IconExport} alt='' />
            <div className='flex items-center'>
              {uploadResults?.length > 1 ? `${uploadResults?.length} Invoices ` : `${uploadResults?.length} Invoice `} Invoices being processed.
            </div>
          </div>
          <div className='w-1 h-16 bg-neutral-70 mr-10' />
          <button
            type='button'
            className='pr-20 flex items-center gap-10 text-green-70 font-semibold'
            onClick={() => {
              setIsMinimized(false);
              document.body.style.overflow = 'hidden';
            }}
          >
            Show Details
          </button>
        </div>
      )}

      {isShowUpload && isMinimized && isAllProcessCompleted && (
        <div className='fixed bottom-16 left-16 z-[9999] px-16 py-10 rounded-6 bg-[#202020] text-white flex items-center'>
          <img src={IconUpload} alt='document upload icon' />
          <div className='flex items-center pl-5 pr-40'>
            Processing of {uploadResults?.length > 1 ? `${uploadResults?.length} Invoices ` : `${uploadResults?.length} Invoice `} Invoices completed.
          </div>
          <div className='w-1 h-16 bg-neutral-70 mr-10' />
          <button
            type='button'
            className='pr-20 flex items-center gap-10 text-green-70 font-semibold'
            onClick={() => {
              setIsMinimized(false);
              document.body.style.overflow = 'hidden';
            }}
          >
            Show Details
          </button>
          <button
            type='button'
            aria-label='dialog box'
            className='font-medium text-primary text-14'
            onClick={() => {
              document.body.style.overflow = 'visible';
              setIsAllProcessCompleted(false);
              onCloseUpload();
              setTimeout(() => {
                onResetUpload();
              }, 500);
            }}
          >
            <CloseIcon sx={{ color: '#808080' }} />
          </button>
        </div>
      )}
    </>
  );
};
