import { ReactNode } from 'react';
import { InvoiceFileProcessingStatusDto, InvoiceUploadSignedUrlDto } from 'src/generated/services/TFinancialApi';

export interface IUploadProps {
  open: boolean;
  onClose: () => void;
}

export enum UploadStep {
  Step1_SelectInvoice = 0,
  Step2_UploadStatus = 1,
}

export interface IUploadFormValues {
  serviceProvider: string;
  modality: string;
  invoiceFiles: File[];
}

export enum OneOffOrRecurring {
  OneOff = 'ONE_OFF',
  Recurring = 'RECURRING',
}

export enum DisputeStep {
  Step1_ContactServiceProvider = 0,
  Step2_Resolution = 1,
  Step3_Summary = 2,
}

export interface IGroupingItemData extends InvoiceUploadSignedUrlDto {
  invoiceDetail?: InvoiceFileProcessingStatusDto;
}
export interface IGroupingItems {
  [key: string]: IGroupingItemData[];
}
export interface IUploadSectionProvidersProps {
  data: IGroupingItemData[];
  children: ReactNode;
  defaultExpanded?: boolean;
}

export interface IUploadSectionProps {
  header: ReactNode;
  children: ReactNode;
  defaultExpanded?: boolean;
}
export interface IUploadSectionLoaderProps {
  children: ReactNode;
  defaultExpanded?: boolean;
}
