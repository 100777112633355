import { FC, useMemo } from 'react';

import TransportMediumIcon from '../card/TransportMediumIcon';
import { IUploadSectionProvidersProps } from './types';
import { UploadSection } from './Section';

export const UploadSectionProvider: FC<IUploadSectionProvidersProps> = ({ data, children, defaultExpanded = false }) => {
  const invoiceDetail = useMemo(() => data?.[0]?.invoiceDetail, [data]);

  const modalityTitle = useMemo(() => {
    switch (invoiceDetail?.modality) {
      case 'CT':
        return 'CONTROL TOWER';
      case 'COURIER':
        return 'COURIER';
      default:
        return invoiceDetail?.modality;
    }
  }, [invoiceDetail?.modality]);

  return (
    <UploadSection
      defaultExpanded={defaultExpanded}
      header={
        <div className='flex items-center'>
          <div className='flex-1'>
            <div className='flex-1 flex items-center gap-12'>
              <TransportMediumIcon medium={invoiceDetail?.modality} iconColor='#9F9F9F' />
              <div className='flex items-center gap-8'>
                <strong className='text-16 font-bold'>{invoiceDetail?.serviceProviderCode}</strong>
                <div className='w-4 h-4 bg-blue_grey-30 rounded-full' />
                <strong className='text-14 font-medium text-grey-40'>{modalityTitle}</strong>
                <div className='w-4 h-4 bg-blue_grey-30 rounded-full' />
                <div className='text-14 font-bold'>{data?.length} items</div>
              </div>
            </div>
          </div>
        </div>
      }
    >
      {children}
    </UploadSection>
  );
};
